import React from 'react'


const CardMapa = () => {
    return (
        <div>
<div class="container-fluid">
<div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<div class="card bg-dark text-white f-sinborde fondo c-general">
  <img src="./img/sector6.jpg" class="card-img" alt="..."/>
  <div class="card-img-overlay">
   
  </div>
</div>
</div>
<div class="col-md-1"></div>
</div>

</div><br/><br/><br/>
        </div>
    )
}
export default CardMapa
import React from 'react'
import './Card.css';
import { Link } from "react-router-dom";
import Backgroud from './backgroundImplementa.png';

const CardImplementa = () => {
    return (
        <div>
<div class="container-fluid back-fondo-implementa" style={{background: "linear-gradient(303.66deg, #1C3E43 12.04%, #2C666E 57.91%)", backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize:'100% 100%', position:'relative' }}>
<div class="row">
<div class="col-md-12">
<div class="card mb-3 f-sinborde fondo c-general" >
  <div class="row g-0">
   <div class="col-md-1"></div>
    <div class="col-md-5">
      <div class="card-body ">
        <br/><br/> 
        <h1 class="card-title-implementa">¡Administra tus ventas en línea desde una sola plataforma!.</h1><br/><br/>
        <p class="card-text">Publica masivamente. Sincroniza existencias, precios, clientes, órdenes. Imprime guías de envío, responde preguntas, bloquea usuarios, y mucho más desde una sola plataforma!</p>
        <br/> <br/>
        <a  href='https://app.microzync.io/register' target='_self' className='btn obtener-demo'>Probar ahora</a>&nbsp;&nbsp;
        <a  href='whatsapp://send?text=Hola, estoy interesado en su producto.&amp;phone=+5215564560704' target='_blank' className='btn contactar-ventas'>Hablar con alguien</a><br/>

      </div>
    </div>
    <div class="col-md-6">
    <br/>
    <img src='./img/inicio.svg' class="img-responsive-implementa" height='auto' width='100%'  alt=""/>
    </div>
  </div>
</div>
</div>
</div>
</div>
<br/><br/><br/>   

        </div>
    )
}

export default CardImplementa;

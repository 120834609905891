import React from 'react'

const CardDudas = () => {
    return (
        <div>
<div class="container-fluid">
<div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<div class="card mb-3 f-sinborde fondo c-general" >
  <div class="row g-0">
    <div class="col-md-6">
      <div class="card-body ">
        <br/><br/><br/>
        <h1 class="card-title-dudas">¿Dudas y sugerencias acerca del producto eMonedero?</h1><br/>
        <p class="card-text">Si existe alguna duda, sugerencia o reclamo acerca de el funcionamiento del producto eMonedero o busca alguna implementación personalizada para su negocio, rellene el formulario con sus datos y preguntas; un agente especializado de "Atti Solutions" se pondrá en contacto con usted para aclarar sus preguntas.</p>
        <br/>
      </div>
    </div>
    <div class="col-md-6">
    <img src="./img/sendmessage.png"  height='auto' width='100%'   alt="..." />
    </div>
  </div>
</div>
</div>
<div class="col-md-1"></div>
</div>
</div>
        </div>
    )
}
export default CardDudas
import React from "react";

const CardSeccion2 = () => {

    return (
        <div>
<div class="container-fluid" >
<div class="row">
<div className="col-md-3"></div>
<div className="col-md-6 text-center">
        <h1 class="card-title-configurar">
        ¿Tienes cientos de artículos que te gustaría llevar a una tienda online?
        </h1>
        <p className="card-text-ofrecer">Conecta  tu punto de venta o ERP con tu comercio electrónico, envía y actualiza +6000 productos de forma automática entre uno o más comercios: </p>
</div>
<div className="col-md-3"></div>
<div className="col-md-1"></div>
<div className="col-md-10 text-center">
    <img src="./img/Comercios.svg" style={{ width: "100%"}}/>
</div>
<div className="col-md-1"></div>

</div>
    </div>
    </div>
    )
}

export default CardSeccion2
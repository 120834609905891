import React from 'react'
import './Container.css'

const ContainerConfigurar = () => {
    return (
        <div>
        <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
        <img src='./img/sector14.png'  height='auto' width='100%'  alt=""/>
        </div>
        <div class="col-md-1"></div>
        </div>
        </div>
    )
}
export default ContainerConfigurar;
import React from "react";

const CardSeccion5 = () => {

    return (
        <div>
<div class="container-fluid" ><br/><br/>
<div class="row">
<div className="col-md-1 "></div>
   
    <div className="col-md-5">
              <img src="./img/seccion5.svg" style={{width: "100%"}}/>
    </div>
    <div className="col-md-5 ">
    <br/>
    <br/>
    <button className="btn multiplscon">Control automático de stock</button>
    <br/>
    <br/>
        <h2 class="card-title-configurar">¡Olvídate del Control Manual y benefíciate de la sincronización automática!</h2>
        <br/>
        <p className="card-text-ofrecer">Cada vez que vendas en tu eCommerce, MicroZync registra la venta y descuenta automáticamente el stock en tu sistema base ¡Despídete de los errores de inventario! </p>
    </div>
    </div>
    </div>
    </div>
    )
}

export default CardSeccion5
import React from 'react'
import './Container.css'
import { Link } from "react-router-dom";

const ContainerConfigurar = ({datosItems, handleAddProduct}) => {
    return (
        <div>
         <div class="container-fluid" > 
         <div class="row">
         <div class="col-md-1"></div>
         <div class="col-md-10">
         <div class="row">
         <div class="col-md-2"></div>
         <div class="col-md-8">
         <h1 class="card-title-planes">Comparativa de planes</h1><br/>
         <p class="card-text-plan">Compare y escoja de acuerdo a las necesidades de su negocio. Si desea personalizar un plan, contacte con nosotros y le ofrecemos asesoramiento sobre las alternativas que tenemos para usted.</p>
         </div>
         <div class="col-md-2"></div>
         </div><br/>

         <div class="row">
         <div class="col-md-12">  
         <div class="respon">  
         <table class="table table-borderless">
         <tr>
         <td></td>
         <td class="center"><p><label class="card-text-subtitulo2">¡Gratis!</label> <br/>$0.00 mes</p></td>
         <td class="center"><strong>Start </strong><p>$1699 MXN /mes</p></td>
         <td class="center"><strong>Pro </strong><p>$3499 MXN /mes</p></td>
         <td class="center"><strong>Personalizado </strong><p>Cotizar</p></td>
         </tr>
         <tr>
         <td class="bg"><label class="card-text-subtitulo">Característica</label></td>
         <td class="bg"></td>
         <td class="bg"></td>
         <td class="bg"></td>
         <td class="bg"></td>
         </tr>
         <tr>
         <td class="linea"><label class="text-plan">Prueba gratuita</label></td>
         <td class="center linea "><i class="verde bi bi-check-lg"></i></td>
         <td class="center linea "><i class="azul bi bi-check-lg"></i></td>
         <td class="center linea "><i class="verde bi bi-check-lg"></i></td>
         <td class="center linea "><i class="verde bi bi-check-lg"></i></td>
         </tr>
         <tr>
         <td class="linea"><label class="text-plan">Funcionalidades completas</label></td>
         <td class="linea"></td>
         <td class="center linea "><i class="azul bi bi-check-lg"></i></td>
         <td class="center linea "><i class="verde bi bi-check-lg"></i></td>
         <td class="center linea "><i class="verde bi bi-check-lg"></i></td>
         </tr>
         <tr>
         <td class="linea"><label class="text-plan">Número de clientes registrados</label></td>
         <td class="center linea "><label class="rojo">20</label></td>
         <td class="center linea "><label class="azul">200</label></td>
         <td class="center linea "><label class="verde">1,000</label></td>
         <td class="center linea "><label class="verde">Personalizado</label></td>
         </tr>
         <tr>
         <td class="linea"><label class="text-plan">Número de sucursales</label></td>
         <td class="center linea "><label class="rojo">1</label></td>
         <td class="center linea "><label class="azul">2</label></td>
         <td class="center linea "><label class="verde">4</label></td>
         <td class="center linea "><label class="verde">Personalizado</label></td>
         </tr>
         <tr>
         <td class="linea"><label class="text-plan">Soporte técnico</label></td>
         <td class="center linea "><i class="verde bi bi-check-lg"></i></td>
         <td class="center linea "><i class="azul bi bi-check-lg"></i></td>
         <td class="center linea "><i class="verde bi bi-check-lg"></i></td>
         <td class="center linea "><i class="verde bi bi-check-lg"></i></td>
         </tr>
         <tr>
         <td class="linea"><label class="text-plan">Actualizaciones</label></td>
         <td class="center linea "><i class="verde bi bi-check-lg"></i></td>
         <td class="center linea "><i class="azul bi bi-check-lg"></i></td>
         <td class="center linea "><i class="verde bi bi-check-lg"></i></td>
         <td class="center linea "><i class="verde bi bi-check-lg"></i></td>
         </tr>
         <tr>
         <td class="linea"><label class="text-plan">Accesos a administrador</label></td>
         <td class="center linea "><label class="verde">1</label></td>
         <td class="center linea "><label class="azul">2</label></td>
         <td class="center linea "><label class="verde">4</label></td>
         <td class="center linea "><label class="verde">Personalizado</label></td>
         </tr>
         <tr>
         <td class="linea"><label class="text-plan">Plan adaptable</label></td>
         <td class="linea"></td>
         <td class="linea"></td>
         <td class="linea"></td>
         <td class="center linea "><i class="verde bi bi-check-lg"></i></td>
         </tr>
         <tr>
         <td class="linea"><label class="text-plan">Adaptaciones gráficas</label></td>
         <td class="linea"></td>
         <td class="center linea "><i class="azul bi bi-check-lg"></i></td>
         <td class="center linea "><i class="verde bi bi-check-lg"></i></td>
         <td class="center linea"><i class="verde bi bi-check-lg"></i></td>
         </tr>
         <tr>
         <td ></td>
         <td class="center"><button className="product-add-button" ><img src='./img/sector13@3x.png' class="responsive-boton2" alt=""/></button></td>
         {datosItems.map((productItem) => ( 
         <td className={productItem.estilo}><button className="product-add-button" onClick={() => handleAddProduct(productItem)}><Link to='/cart'><img src={productItem.imagen} class="responsive-boton2" alt=""/></Link></button></td>
         ))}
         <td class="center"><button className="product-add-button" ><img src='./img/sector13@3x.png' class="responsive-boton2"  alt=""/></button></td>
         </tr>
         </table>
         </div> 
         </div>
         </div>
         </div>
         <div class="col-md-1"></div>
         </div>
         </div><br/><br/><br/>
        </div>
    )
}
export default ContainerConfigurar;
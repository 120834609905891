import React from "react";

const Carousel = () => {

    return (
        <div>
  <div class="container-fluid" >   
  <div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">

<div class="row">
  <div class="col-md-3"></div>
  <div class="col-md-6">
  <h6 class="titulo-administrador" >PANTALLAS DE USUARIO ADMINISTRADOR</h6><br/>
  <h1 class="subtitulo-administrador"><label class="clientes">eMonedero DasBoard</label></h1><br/>
  </div>
  <div class="col-md-3"></div>
  </div>
         

<div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
    <img src='./img/vista1.png' class="d-block w-100 img-responsive-implementa"  height='auto' width='100%' alt="..."/>
    </div>
    <div class="carousel-item">
    <img src='./img/vista2.png' class="d-block w-100 img-responsive-implementa" height='auto' width='100%' alt="..."/>
    </div>
    <div class="carousel-item">
    <img src='./img/vista3.png' class="d-block w-100 img-responsive-implementa"  height='auto' width='100% ' alt="..."/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</div>
<div class="col-md-1"></div>
</div>  

</div>  
<br/><br/><br/><br/><br/>
        </div>
    )
}

export default Carousel
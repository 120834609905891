import React from 'react'
import CardAdministrador from '../card/CardAdministrador';
import CardBolsillo from '../card/CardBolsillo';
import Carousel from '../carousel/carousel';
import ContainerConfigurar from '../container/ContainerConfigurar';
import FooterInicio from '../footer/FooterInicio';
import NavbarConfigurar from '../navbar/NavbarConfigurar';

const Configurar = () => {
    return (
        <div>
         <NavbarConfigurar/>   
         <ContainerConfigurar/>
         <CardAdministrador/>
         <CardBolsillo/>
         <Carousel/>
         <FooterInicio/>      
        </div>
    )
}
export default Configurar;
import React, {useState} from 'react';
import { BrowserRouter, Routes,Route} from 'react-router-dom'
import Inicio from './components/paginas/Inicio';
import Planesprecios from './components/paginas/Planesprecios';
import Configurar from './components/paginas/Configurar';
import Contacto from './components/paginas/Contacto';
import Data from "./components/data/Data";
import Planes from "./components/data/Planes";
import Datos from "./components/data/Datos";
import Cart from './components/paginas/Cart';

function App() {
  const {productItems}=Data; 
  const {planItems}=Planes;
  const {datosItems}=Datos;
  const [cartItems, setCartItems] = useState([]);
  const handleAddProduct =(product) =>{
    const ProductExist= cartItems.find((item) => item.id === product.id);
    if(ProductExist){
      setCartItems(cartItems.map((item) => item.id === product.id ?{...ProductExist, quantity: ProductExist.quantity +1}: item));

    } else{
     setCartItems([...cartItems,{...product, quantity: 1}]);
    }
  }

  const handleRemoveProduct = (product) => {
    const ProductExist= cartItems.find((item) => item.id === product.id);
    if(ProductExist.quantity === 1){
     setCartItems(cartItems.filter((item) => item.id !== product.id));
    } else{
      setCartItems(cartItems.map((item) => item.id === product.id ? {...ProductExist, quantity: ProductExist.quantity -1}: item));
    }
  }

  const handleCartClearance = () =>{
  setCartItems([]);
  }

  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
      <Route path='/' element={<Inicio cartItems={cartItems} productItems={productItems} handleAddProduct={handleAddProduct}/>}/>
      <Route path='/planes' element={<Planesprecios cartItems={cartItems} planItems={planItems} datosItems={datosItems} handleAddProduct={handleAddProduct}/>}/>
      <Route path='/configurar' element={<Configurar/>} />
      <Route path='/contacto' element={<Contacto/>} />
      <Route path='/cart' element={<Cart cartItems={cartItems} handleAddProduct={handleAddProduct} handleRemoveProduct={handleRemoveProduct} handleCartClearance={handleCartClearance}/>} />
      <Route path="/atti" element={() => {global.window && (global.window.location.href = 'https://atti.com.mx/');return null;}}/>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from 'react'
import CardBeneficios from '../card/CardBeneficios';
import CardEconomia from '../card/CardEconomia';
import CardSeccion2 from '../card/CardSeccion2';
import CardSeccion3 from '../card/CardSeccion3';
import CardSeccion4 from '../card/CardSeccion4';
import CardSeccion5 from '../card/CardSeccion5';
import CardSeccion6 from '../card/CardSeccion6';
import CardSeccion7 from '../card/CardSeccion7';
import CardSeccion8 from '../card/CardSeccion8';
import CardSeccion9 from '../card/CardSeccion9';
import CardSeccion10 from '../card/CardSeccion10';
import CardEcosistema from '../card/CardEcosistema';
import CardImplementa from "../card/CardImplementa";
import CardIntegracion from '../card/CardIntegracion';
import CardOfrecer from '../card/CardOfrecer';
import CardRecompensas from '../card/CardRecompensas';
import CardSistema from '../card/CardSistema';
import FooterInicio from '../footer/FooterInicio';
import NavbarInicio from '../navbar/NavbarInicio';
import CardPlanesPrecios from '../card/CardPlanesPrecios';
import ContainerInterfaz from '../container/ContainerInterfaz';
const Inicio = ({cartItems, productItems , handleAddProduct}) => {
    return (
        <div>
        <NavbarInicio cartItems={cartItems}/>   
        <CardImplementa/>
        <CardSeccion2/>
        <CardSeccion3/>
        <CardSeccion4/>
        <CardSeccion5/>
        <CardSeccion6/>
        <CardSeccion7/>
        <CardSeccion8/>
        <CardSeccion9/>
        
        
        <FooterInicio/>  
        </div>
    )
}
export default Inicio;

//<CardSeccion10/>
//<CardPlanesPrecios productItems={productItems} handleAddProduct={handleAddProduct}/>
import React from 'react'
import CardContacto from '../card/CardContacto';
import CardDudas from '../card/CardDudas';
import CardMapa from '../card/CardMapa';
import ContainerContacto from '../container/ContainerContacto';
import FooterInicio from '../footer/FooterInicio';
import NavbarContacto from '../navbar/NavbarContacto';

const Contacto = () => {
    return (
        <div>
            <NavbarContacto/>
            <ContainerContacto/>
            <CardDudas/>
            <CardContacto/>
            <CardMapa/>
            <FooterInicio/> 
        </div>
    )
}
export default Contacto;
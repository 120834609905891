import React from 'react'
import { Link } from "react-router-dom";

const CardContacto = () => {
    return (
        <div>
 <div class="container-fluid" >
 <div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<div class="container-fluid">           
        <div className="card mb-3 f-sinborde" >
         <div className="row g-5">
           <div className="col-md-6">
             <div className="card-body">
               <h6 className="titulo-sistema">LLÁMANOS</h6>
               <h2 className="card-title-contacto">Contacto</h2><br/>               
               <p className="card-text">Para una atención pronta y personalizada contacta a nuestros números.</p>
               <a className="tel" href="tel:5564560704"><i class="telefono bi bi-telephone-fill"></i>  55 64 56 07 04</a><br/>
               <a className="tel" href="tel:9511671455"><i class="telefono bi bi-telephone-fill"></i>  951 167 14 55</a><br/>
               <p className="card-text">Miguel Cabrera #627 Centro <br/> Oaxaca, México<br/> Lunes a viernes <br/> 9:00 am - 18:00 pm <br/> <a href="https://atti.com.mx" className="">www.atti.com.mx</a> </p>
               <img src='./img/sector4@3x.png'  className="card-img"  alt="..."/>

             </div>
           </div>
           <div class="col-md-6">
           <h6 class="titulo-formulario" >ESCRÍBENOS</h6>
           <h2 class="card-title-contacto">Formulario</h2><br/>
           <form method="post" id="sample_form" class="form-horizontal" enctype="multipart/form-data" autocomplete="off">
                            
                            <div class="form-group">
                                <div class="col-md-12">
                                <input type="text" name="nombreCliente" id="nombreCliente" placeholder="Nombre completo" class="form-control" required />
                                </div>
                            </div><br/>
                            <div class="form-group">
                                <div class="col-md-12">
                                    <input type="email" name="correoCliente" id="correoCliente" placeholder="Correo electrónico" class="form-control" />
                                </div>
                            </div><br/>
                            <div class="form-group">
                                <div class="col-md-12">
                                    <input type="tel" name="telefonoCliente" id="telefonoCliente" placeholder="Número de teléfono"  class="form-control" title="Ingrese un numero telefonico valido" required />
                                </div>
                            </div><br/>
                            <div class="form-group">
                                <div class="col-md-12">
                                    <input type="text" name="asuntoCliente" id="asuntoCliente" placeholder="Asunto" class="form-control" />
                                </div>
                            </div><br/>
                            <div class="form-group">
                                <div class="col-md-12">
                                <textarea type="text" name="textarea" id="textarea" rows="5" cols="33" placeholder="Correo electrónico"></textarea>
                                </div>
                            </div><br/>
                            <div class="form-group" >
                                <input type="hidden" name="action" id="action" />
                                <input type="hidden" name="hidden_id" id="hidden_id" />
                                <button type="submit" class ="enviar" name="action_button" id="action_button" value="Add"><img src='./img/sector5@3x.png' class="img-responsive-boton4" height='auto' width='100%' alt=""/></button>
                            </div>
                        </form>

           </div>
         </div>
       </div>   
       </div>
</div>
<div class="col-md-1"></div>
</div>

 </div>     
 <br/><br/> 
       </div>
    )
}
export default CardContacto;
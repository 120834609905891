import React from "react";

const CardSeccion8 = () => {

    return (
        <div>
<div class="" ><br/><br/>
<div class="col-md-12 row">
<div className="col-md-2"></div>
    
    <div className="col-6 col-md-3">
        <img src="./img/sicar.svg" style={{ height: "3vw", width: "auto"}}/>
        <h4 class="card-title-configurarh4">Conexiones Sicar</h4>
        <ul style={{listStyle: "none",paddingLeft: "0px"}} className="card-text-ofrecerp">
            <li><input type="checkbox" checked disabled/>&nbsp;Conecta Sicar con TiendaNube</li>
            <li><input type="checkbox" checked disabled/>&nbsp;Conecta Sicar con Amazon</li>
            <li><input type="checkbox" checked disabled/>&nbsp;Conecta Sicar con MercadoLibre</li>
            <li><input type="checkbox" checked disabled/>&nbsp;Conecta Sicar con WooCommerce</li>
            <li><input type="checkbox" checked disabled/>&nbsp;Conecta Sicar con Shopify</li>
            <li><input type="checkbox" checked disabled/>&nbsp;Conecta Sicar con Wix</li>
        </ul>
    </div>
    <div className="col-md-3">
        <img src="./img/conpaqi.svg" style={{ height: "3vw", width: "auto"}}/>
        <h4 class="card-title-configurarh4">Conexiones ContpaQi</h4>
        <ul style={{listStyle: "none",paddingLeft: "0px"}} className="card-text-ofrecerp">
            <li><input type="checkbox" checked disabled/>&nbsp;Conecta ContpaQi con TiendaNube</li>
            <li><input type="checkbox" checked disabled/>&nbsp;Conecta ContpaQi con Amazon</li>
            <li><input type="checkbox" checked disabled/>&nbsp;Conecta ContpaQi con MercadoLibre</li>
            <li><input type="checkbox" checked disabled/>&nbsp;Conecta ContpaQi con WooCommerce</li>
            <li><input type="checkbox" checked disabled/>&nbsp;Conecta ContpaQi con Shopify</li>
            <li><input type="checkbox" checked disabled/>&nbsp;Conecta ContpaQi con Wix</li>
        </ul>
    </div>
    <div className="col-md-3">
        <img src="./img/microsip.svg" style={{ height: "3vw", width: "auto"}}/>
        <h4 class="card-title-configurarh4">Conexiones Microsip</h4>
        <ul style={{listStyle: "none",paddingLeft: "0px"}} className="card-text-ofrecerp"> 
            <li><input type="checkbox" checked disabled/>&nbsp;Conecta Microsip  con TiendaNube</li>
            <li><input type="checkbox" checked disabled/>&nbsp;Conecta Microsip  con Amazon</li>
            <li><input type="checkbox" checked disabled/>&nbsp;Conecta Microsip  con MercadoLibre</li>
            <li><input type="checkbox" checked disabled/>&nbsp;Conecta Microsip  con WooCommerce</li>
            <li><input type="checkbox" checked disabled/>&nbsp;Conecta Microsip  con Shopify</li>
            <li><input type="checkbox" checked disabled/>&nbsp;Conecta Microsip  con Wix</li>
        </ul>
    </div>
    </div>
    </div>
    </div>
    )
}

export default CardSeccion8
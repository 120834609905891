import React from "react";

const CardSeccion7 = () => {

    return (
        <div>
<div class="container-fluid" ><br/><br/>
<div class="row">
<div className="col-md-2"></div>
   
    <div className="col-md-8 text-center">
             <b style={{color: "rgba(44, 102, 110, 1)",fontSize: "1.2vw"}}>Conexiones de tienda</b>
             <h2 class="card-title-configurar">¿Vendes en diferentes plataformas o tienes varias tiendas en Mercado Libre?</h2>
             <p className="card-text-ofrecer"> Si vendes en Shopify, Tiendanube, WooCommerce o tienes varias tiendas en Mercado Libre. Con MicroZync puedes gestionar todo desde una sola herramienta, como si fuera un solo comercio. Organiza tu inventario con stock separado, duplica publicaciones, sincroniza automáticamente, y lleva el control de todas tus ventas directamente a tu sistema base. </p>
    </div>
    </div>
    </div>
    </div>
    )
}

export default CardSeccion7
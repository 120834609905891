import React from "react";
import { Link } from "react-router-dom";

const CardSeccion9 = () => {

    return (
        <div>
<div class="container-fluid" ><br/><br/>
<div class="row">
<div className="col-md-3"></div>
   
    <div className="col-md-6 text-center">
             <h2 class="card-title-configurar">¡Toda la administración de tu negocio, centralizada desde un solo lugar!</h2>
             <p className="card-text-ofrecer">¿Tuviste una venta en tu tienda física y el mismo producto se vendió online? 
                ¡Evita el riesgo de vender productos sin stock! Con MicroZync, controla tu inventario 
                físico y online de forma centralizada y en tiempo real. Gestiona tu stock en ambos 
                canales sin problemas y mantén siempre actualizada tu disponibilidad.</p>
                <div className="text-center">
                    <a href='https://app.microzync.io/register' target='_self' className='btn obtener-demo'>Probar ahora</a>&nbsp;&nbsp;
                    <a  href='whatsapp://send?text=Hola, estoy interesado en su producto.&amp;phone=+5215564560704' target='_blank' className='btn contactar-ventas2'>Contactar a ventas</a><br/>
                </div>
    </div>
    </div>
    </div>
    <br/>
    </div>

    )
}

export default CardSeccion9
import React from "react";
import NavbarCart from '../navbar/NavbarCart';
import FooterInicio from "../footer/FooterInicio";
import './cart.css';
import ReactDOM from "react-dom"
const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

const Cart = ({ cartItems, handleAddProduct, handleRemoveProduct, handleCartClearance}) => {
    const totalPrice= cartItems.reduce((price, item) => price + item.quantity * item.price, 0);

    const createOrder = (data, actions) =>{
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: totalPrice,
                currency_code: "MXN",
              },
            },
          ],
        });
        };
    
    const onApprove = (data, actions) => {
            return actions.order.capture(handlePay());
          };
    function handlePay(){
        console.log("El pago ha sido exitoso");
        window.location.href='/';
    }
   
    return (
        <div>
        <NavbarCart cartItems={cartItems} />
        <div className="cart-items">
        <h2 className="cart-items-header">Artículos del carrito</h2>
        <div className="clear-cart">
         {cartItems.length >=1 && (
             <button className="clear-cart-button" onClick={handleCartClearance}>Limpiar carrito</button>
         )}
        </div>
        {cartItems.length === 0 && (
            <div className= "cart-items-empty">Productos no agregados</div>
        )}
        <div>
            {cartItems.map((item) =>(
                <div key={item.id} className="cart-items-list">
                   <div className="cart-items-name">{item.name}</div>
                   <div className="cart-items-function">
                    <button className="cart-items-add" onClick={() => handleAddProduct(item)}>+</button>
                    <button className="cart-items-remove" onClick={() => handleRemoveProduct(item)}>-</button>
                    <div className="cart-items-price">{item.quantity} * ${item.price}</div>
                   </div>
                </div>
            ))}
        </div>
        <div className="cart-items-total-price-name">
            Total precio 
        <div className="cart-items-total-price"> ${totalPrice}</div>
        </div>
        </div>
        <h2 className="monto"><strong> El monto es ${totalPrice}.00</strong></h2><br/>
         <div  className="paypal">
         <PayPalButton className="paypal"
        createOrder={(data, actions) => createOrder(data, actions)}
        onApprove={(data, actions) => onApprove(data, actions)}
         />
         </div>
         <br/><br/>
         <FooterInicio/>
        </div>
    )
}

export default Cart
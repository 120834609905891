import React from 'react'
import Backgroud from './sector17.jpg';
import './Container.css'

const ContainerContacto = () => {
    return (
        <div>
        <header className="back-contacto" style={{background:`url(${Backgroud})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize:'100% 100%', position:'relative' }}>
        <br/><br/><br/><br/>
        <h1 className="titulo-funcion">Contacto</h1>
        </header>
        <br/><br/><br/>
        </div>
    )
}
export default ContainerContacto;
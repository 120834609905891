import React from 'react'
import ContainerPlan from '../container/ContainerPlan';
import ContainerPlanPrecio from '../container/ContainerPlanPrecio';
import FooterInicio from '../footer/FooterInicio';
import CardPlanes from '../card/CardPlanes';
import ContainerComparativa from '../container/ContainerComparativa';
import NavbarPlanes from '../navbar/NavbarPlanes';
const Planesprecios = ({cartItems, planItems , datosItems, handleAddProduct}) => {
    return (
        <div>
            <NavbarPlanes cartItems={cartItems}/>
            <ContainerPlanPrecio/>
            <CardPlanes planItems={planItems} handleAddProduct={handleAddProduct}/>
            <ContainerComparativa datosItems={datosItems} handleAddProduct={handleAddProduct}/>
            <ContainerPlan/>  
            <FooterInicio/>
        </div>
    )
}
export default Planesprecios;
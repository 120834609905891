import React from 'react'
import './Navbar.css';
import { Link } from 'react-router-dom';

const NavbarConfigurar = () => {
    return (
        <div>
  <nav className="navbar navbar-expand-lg navbar-light ">
  <div className="container-fluid">
    <Link to=''><img src='./img/Microzync.svg'   className='mx-2 img-responsive'   height='100%' width='100%' alt=""/> </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNavDropdown">
      <ul className="navbar-nav mx-auto">
       {/*} <li className="nav-item">
          <Link className="nav-link n-inicio" aria-current="page" to='/'>Inicio</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link n-inicio" to='/planes'>Planes</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link active i-active" to='/configurar'>Como funciona</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link n-inicio" to='/contacto'>Contacto</Link>
        </li>*/}
        <li className="nav-item">
          <Link className="nav-link n-inicio" to='/planes'><img src='./img/sector3@2x.png'  class="boton-probar" height='auto' width='100%' alt=""/></Link>
        </li>
      </ul>
    </div>
  </div>
</nav> 
        </div>
    )
}

export default NavbarConfigurar;
import React from "react";
import { Link } from "react-router-dom";

const CardAdministrador = () => {

    return (
        <div>
<div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<div class="container-fluid" >  
<div class="card mb-3 f-sinborde" >
<div class="row g-0">
<div class="row">
  <div class="col-md-2"></div>
  <div class="col-md-8">
  <h6 class="titulo-administrador" >MEJORA LA ECONOMÍA DE TU NEGOCIO</h6><br/>
  <h1 class="subtitulo-administrador">¿Como utilizar mi eMonedero <label class="clientes">Administrador?</label></h1><br/>
  </div>
  <div class="col-md-2"></div>
  </div>
<div class="col-md-6">
<img src='./img/OBJECT.png' height='auto' width='100%' alt=""/>
</div>

<div class="col-md-6">
<div class="card-body">
<h3 class="titulo-emonedero">¿Cómo configurar eMonedero para tu negocio?</h3>  
<p class="card-text-economia">Convierte a tus clientes en consumidores frecuentes de su marca y expanda su mercado con recompensas por referencias. EMonedero es un software que le ayuda a incrementar su cartera de clientes, ventas y el desplazamiento rápido de productos de stock, mediante el desarrollo de programas o campañas.</p>
<ul>
  <li class="card-text-economia">Descarga la aplicación móvil desde la tienda digital de Google Play</li>
  <li class="card-text-economia">Inicia la app y elige el tipo de usuario "Administrador" de negocio</li>
  <li class="card-text-economia">Rellena los datos de registro de usuario para tu negocio</li>
  <li class="card-text-economia">Bienvenido al dashboard de eMonedero y empieza a disfrutar de los beneficios que el sistema te ofrece</li>
  <li class="card-text-economia">Para conectar tu base de datos de usuarios y clientes dirijite al menú lateral de desplazamiento y selecciona "Conectar base de datos"</li>
</ul>
<p class="nota">¡Si necesitas ver un tutorial en video de cómo crear una campaña para tus clientes en eMonedero cliquea en el enlace!</p><br/>
<p class="aprende"> <Link to=''><img src='./img/sector6@3x.png' class="img-responsive-boton4" height='auto' width='100%' alt=""/></Link></p>

    </div>
    </div>
 
  </div>
</div>
</div><br/><br/>
</div>
<div class="col-md-1"></div>
</div>
    </div>
    )
}

export default CardAdministrador
const data ={
    datosItems:[

        {
            id:"2",
            name:"start",
            imagen:"./img/sector13@3x.png",
            estilo:"center",
            price:"1699"
        },
        {
            id:"3",
            name:"Pro",
            imagen:"./img/sector13@3x.png",
            estilo:"center",
            price:"3499"
        },
    ]

}

export default data;
import React from 'react'
import Backgroud from './sector1.jpg';
import './Container.css'

const ContainerConfigurar = () => {
    return (
        <div>
        <div class="container-fluid  back-contacto" style={{background:`url(${Backgroud})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize:'100% 100%', position:'relative' }}>
        <br/><br/><br/><br/>
        <h1 class="titulo-funcion" >Planes y Precios</h1>
        </div><br/><br/>
        </div>
    )
}
export default ContainerConfigurar;
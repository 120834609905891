const data ={
    planItems:[

        {
            id: "2",
            Background:"./img/sector9@3x.png",
            name:"Start",
            subtitulo:"$",
            subtitulo2:"/ Mes",
            texto:"Ideal para negocios en proceso de crecimiento, con una capacidad de almacenamiento de 200 clientes y dos sucursales.",
            imagen:"./img/sector7@3x.png",
            nota:"Reclamar prueba.",
            estilo1:"subtituloPlan2",
            estilo2:"pagoPlan2",
            estilo3:"peso2",
            estilo4:"card-text-plan2",
            estilo5:"card-text-plan-line2",
            estilo6:"product-add-button",
            estilo7:"plan5",
            price:"1699"
        },
        {
            id: "3",
            Background:"./img/sector10@3x.png",
            name:"Pro",
            subtitulo:"$",
            subtitulo2:"/ Mes",
            texto:"Para negocios establecidos con registro de 1,000 clientes y registro de cuatro sucursales.",
            imagen:"./img/sector7@3x.png",
            nota:"Reclamar prueba.",
            estilo1:"subtituloPlan2",
            estilo2:"pagoPlan2",
            estilo3:"peso2",
            estilo4:"card-text-plan2",
            estilo5:"card-text-plan-line2",
            estilo6:"product-add-button2",
            estilo7:"plan6",
            price:"3499"
        },

    ]

}

export default data;
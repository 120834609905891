import React from "react";

const CardSeccion4 = () => {

    return (
        <div>
<div class="container-fluid" ><br/><br/>
<div class="row">
<div className="col-md-1 "></div>
    <div className="col-md-5 ">
    <br/>
    <br/>
    <button className="btn multiplscon">Múltiples conexiones</button>
    <br/>
    <br/>
    
        <h2 class="card-title-configurar">Administra múltiples cuentas de Mercado Libre</h2>
        <br/>
        <p className="card-text-ofrecer">Conecta dos o más cuentas de ML y distribuye tu stock de Sicar, CONTPAQ¡ o Microsip de manera fácil y centralizada. Duplica publicaciones al instante, ajusta tu inventario de forma personalizada y pon un umbral de stock para controlar las ventas sin esfuerzo. ¡Todo desde un solo sistema!</p>
    </div>
    <div className="col-md-5">
              <img src="./img/seccion4.svg" style={{width: "100%"}}/>
    </div>
    </div>
    </div>
    </div>
    )
}

export default CardSeccion4
import React from "react";
import { Link } from "react-router-dom";
import './Card.css'

const CardBolsillo = () => {

    return (
        <div>
<div class="container-fluid" >
<div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<div class="card mb-3 f-sinborde" >
<div class="row g-0">
<div class="row">
  <div class="col-md-3"></div>
  <div class="col-md-6">
  <h6 class="titulo-administrador" >MEJORA LA ECONOMÍA DE TU BOLSILLO</h6><br/>
  <h1 class="subtitulo-administrador">¿Como utilizar mi eMonedero <label class="clientes">Cliente?</label></h1><br/>
  </div>
  <div class="col-md-3"></div>
  </div>


<div class="col-md-6">
<div class="card-body">
<h5 class="card-text-bolsillo">¿Cómo configurar eMonedero usuario-cliente?</h5>
<p class="card-text-economia">Registrese y empiece a disfrutar de los beneficios que su tienda de conveniencia le ofrece. Reciba ofertas de productos especiales, descuentos en mercancía especializada, puntos de bonificación por compra entre otras muchas mas opciones de las que puede ser beneficio. Gane puntos desde su primera suscripción y gane más interactuando con la app de eMonedero.</p>
<ul>
  <li class="card-text-economia">Descarga la aplicación móvil desde la tienda digital de Google Play</li>
  <li class="card-text-economia">Inicia la app y elige el tipo de usuario "Cliente"</li>
  <li class="card-text-economia">Rellena los datos de registro de usuario para cliente</li>
  <li class="card-text-economia">Bienvenido al dashboard de eMonedero</li>
  <li class="card-text-economia">En cada compra que realices en tu tienda de conveniencia, se registrará en tu monedero electrónico una porción de cash digital con el que podrás beneficiarte en tus siguientes compras: Descuentos, canjes por puntos acumulados entre otras muchas opciones.</li>
</ul>
<p class="nota">¡Si necesitas ver un tutorial en video sobre como utilizar la app eMonedero cliente, de clic en el siguiente enlace!</p><br/>
<p class="aprende"> <Link to=''><img src='./img/sector6@3x.png' class="img-responsive-boton4" height='auto' width='100%' alt=""/></Link></p>
    </div>
    </div>
<div class="col-md-6">
<img src='./img/OBJECT.png'  height='auto' width='100%' alt=""/>
</div>

  </div>
</div>
</div>
<div class="col-md-1"></div>
</div>

</div><br/><br/>
    </div>
    )
}

export default CardBolsillo
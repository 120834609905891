import React from "react";

const CardSeccion6 = () => {

    return (
        <div>
<div class="container-fluid" ><br/><br/>
<div class="row">
<div className="col-md-1 "></div>
   
    <div className="col-md-10">
              <img src="./img/seccion6.svg" style={{width: "100%"}}/>
    </div>
    </div>
    </div>
    </div>
    )
}

export default CardSeccion6
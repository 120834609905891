import React from "react";

const CardSeccion3 = () => {

    return (
        <div>
<div class="container-fluid" ><br/><br/>
<div class="row">
<div className="col-md-1"></div>
    <div className="col-md-5 ">
        <img src="./img/seccion3.svg" style={{ width: "100%"}}/>
    </div>
    <div className="col-md-5">
    <br/>
    <br/>
    <button className="btn multiplscon">Gestión inteligente</button>
    <br/>
    <br/>
        <h2 class="card-title-configurar">Importa automáticamente tus productos desde Sicar, CONTPAQ¡ o Microsip.</h2>
        <br/>
        <p className="card-text-ofrecer">Simplifica la administración de tu tienda y mantén tus existencias y precios siempre actualizados. ¡No más actualizaciones manuales! Además, descarga al instante todas las ventas de tu eCommerce directamente a tu sistema de ventas. Optimiza tus procesos y ahorra tiempo para enfocarte en lo que importa: hacer crecer tu negocio.</p>
        <img src="./img/seccion3.1.svg" style={{height: "5vw"}}/>
    </div>
    </div>
    </div>
    </div>
    )
}

export default CardSeccion3
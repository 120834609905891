import React from "react";
import { Link } from "react-router-dom";
import './Card.css'
import Backgroud1 from './sector8@3x.png';
import Backgroud4 from './sector11@3x.png';

const CardPlanes = ({planItems, handleAddProduct}) => {

    return (
 <div>
   <br/><br/>
 <div class="container-fluid" > 
 <div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<div class="row">
 <div class="col-md-2"></div>
 <div class="col-md-2"><img src='./img/Megaphone@2x_2.png' height='auto' width='100%' alt=""/></div>
 <div class="col-md-5">
 <h1 class="card-title-planes">¡Planes a la medida de tu negocio!</h1><br/>
 </div>
 <div class="col-md-2"><img src='./img/Megaphone@2x.png' height='auto' width='100%' alt=""/></div>
 <div class="col-md-2"></div>
 </div>

 <div class="row">
 <div class="col-md-2"></div>
 <div class="col-md-8">
 <p class="card-text-plan">Si su negocio es pequeño, mediano o grande, el software de fidelización eMonedero está gestionado para adaptarse perfectamente a sus necesidades, pruebe la versión gratuita de nuestros planes durante treinta días y elija plan que mejor se adapte.</p>
 </div>
 <div class="col-md-2"></div>
 </div>
<br/>
<br/>

 <div class="row row-cols-1 row-cols-md-4 g-4">
 <div class="col">
    <div class="card h-100" className="plan4">
      <div class="card-body">
        <h5 className="subtituloPlan2">Libre</h5>
        <h3 className="subtituloPlan3"><strong>!Gratis!</strong></h3><br/><br/>
        <p className="card-text-plan">Restringido a registro de 20 clientes, recomendamos optar por la versión start y probar la versión demo de treinta días.</p><br/><b/><br/><br/>
        <button className="product-add-button" ><img src='./img/sector7@3x.png' height='auto' width='100%' alt=""/></button><br/><br/><br/>
      </div>
    </div>
  </div>

 {planItems.map((productItem) => (  
 <div class="col">
    <div class="plan1 card h-100" className={productItem.estilo7}>
      <div class="card-body">
        <h5 className={productItem.estilo1}>{productItem.name}</h5>
        <p className={productItem.estilo2}><label className={productItem.estilo3}><label className={productItem.estilo3}>{productItem.subtitulo}</label><label class="precio">{productItem.price}</label></label><br/><label class="mes">{productItem.subtitulo2}</label></p>
        <p className={productItem.estilo4}>{productItem.texto}</p><br/><br/>
        <button  className={productItem.estilo6} onClick={() => handleAddProduct(productItem)}><Link to='/cart'><img src={productItem.imagen} class="img-responsive-boton4" height='auto' width='100%' alt=""/></Link><br/><br/><p className="card-text-plan-line2"><Link to='/planes'><label class="reclamar">Reclamar prueba.</label></Link></p></button>
      </div>
    </div>
  </div>
   ))}

<div class="col">
    <div class="card h-100" className="plan7">
      <div class="card-body">
        <h5 className="subtituloPlan2">Personalizado</h5>
        <h3 className="subtituloPlan3"><strong>A tratar</strong></h3><br/><br/>
        <p className="card-text-plan2">Para empresas con gestión de cartera y sucursales ilimitadas.</p><br/><br/><br/><br/><br/><br/><br/><br/>
        <button className="product-add-button" ><img src='./img/sector7@3x.png' height='auto' width='100%' alt=""/><br/><br/></button>
        <p className="card-text-plan-line2"><Link to='/planes'><label class="reclamar">Reclamar prueba.</label></Link></p>
      </div>
    </div>
  </div>
</div>

<br/>
<div class="row">
<div class="form-group col-md-5">
</div>
<div class="form-group col-md-4">
<small class="buscas"><Link to='/contacto'><label class="buscas">Contacta con nosotros</label></Link></small>
</div> 
<div class="form-group col-md-4">
</div>  
</div>
<br></br>
</div>
<div class="col-md-1"></div>
</div>
</div><br/><br/>
  </div>
    )
}

export default CardPlanes